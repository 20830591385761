import { defineStore } from "pinia";
import axios from "axios";
import { ADMIN_LOGIN, ADMIN_PROFILE } from "@/core/config/ApiConfig";

export const useAuthStore = defineStore("auth", {
  state: () => ({
    access_token: null,
    isAuth: false,
    errors: null,
    profile: [],
  }),

  getters: {
    isAuthenticated(state) {
      return state.isAuth;
    },
    hasError(state) {
      return state.errors;
    },
    hasPermission(state) {
      return (moduleName) => {
        if (!moduleName) {
          return true;
        } else if (state.profile && state.profile.read) {
          return state.profile.read.includes(moduleName);
        } else {
          return false;
        }
      };
    },
    checkSensitive(state) {
      return !this.hasPermission("Sensitive");
    },
  },
  persist: {
    enabled: true,
    strategies: [
      {
        key: "access_token",
        storage: sessionStorage,
        paths: ["access_token"],
      },
      { key: "auth", storage: sessionStorage, paths: ["isAuth"] },
      { key: "profile", storage: sessionStorage, paths: ["profile"] },
    ],
  },

  actions: {
    async LOGIN(formData) {
      try {
        const response = await axios.post(
          process.env.VUE_APP_API_URL + ADMIN_LOGIN,
          formData
        );
        if (response.data && response.data.token) {
          this.access_token = response.data.token;
          this.isAuth = true;
          this.errors = null;
        }
      } catch (error) {
        this.errors[0] = error;
      }
    },
    async VERIFY_PROFILE() {
      if (this.profile != []) {
        try {
          const response = await axios.get(
            process.env.VUE_APP_API_URL + ADMIN_PROFILE,
            {
              headers: {
                Authorization: "Bearer " + this.access_token,
              },
            }
          );
          if (response.data) {
            this.profile = response.data;
          }
        } catch (error) {
          if (error.response && error.response.status == 401) {
            this.isAuth = false;
            this.access_token = null;
            window.location.reload();
          } else {
            this.errors = error.message; // Catch other errors
            this.isAuth = false;
            this.access_token = null;
            window.location.reload();
          }
        }
      }
    },
    async UPDATE_PROFILE(formData) {
      let authStore = useAuthStore();

      if (formData.password == "") {
        delete formData.password;
        delete formData.confirm_password;
      }

      formData["id"] = this.profile.id;

      try {
        const response = await axios.patch(
          process.env.VUE_APP_API_URL + ADMIN_PROFILE,
          formData,
          {
            headers: {
              Authorization: "Bearer " + authStore.access_token,
            },
          }
        );
        if (response.data) {
          this.errors = null;
        }
      } catch (error) {
        this.errors = error.response.data.message;
        console.error("An error occurred:", error);
        // throw error;
      }
    },
  },
});
